import axios from "axios";
import React, { useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import SeedCard from "../components/seedcard";
import globals from "../globals";
import ornektohum from "../ornekresimler/ornektohum.jpg";

const CategorizedSeeds = () => {
  const loader = useRef();

  const catIds = useParams();
  const catId = catIds.category;

  const [catName, setCatName] = useState("");

  const [loadCategory, setCategoryLoad] = useState(false);
  const [loadProduct, setProductLoad] = useState(false);

  const tempseedlist = [];
  const [seed_list, setSeedList] = useState([]);

  const categorylist = [];
  const [categories, setCategories] = useState([]);

  const droplist = [];
  const [drops, setDrops] = useState([]);

  if (!loadProduct) {
    if (loader && loader.current) {
      loader.current.style.display = "flex";
    }
    axios
      .get(globals.baseUrl + "/Category?categoryID=" + Number(catId))
      .then((response) => {
        setCatName(response.data.categoryName);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  if (!loadProduct) {
    axios
      .get(
        globals.baseUrl +
          "/Products?Page=1&PageSize=100&categoryId=" +
          Number(catId)
      )
      .then((response) => {
        const seeds = response.data;
        console.log(seeds);
        seeds.forEach((seed) => {
          if (seed.image == null || seed.image === "string") {
            seed.image = ornektohum;
          }
          tempseedlist.push(
            <a href={`/seeds/seed/${seed.id}`}>
              <SeedCard
                image={globals.baseUrl + `/Products/${seed.id}/image`}
                name={seed.name}
                grow="5-6 hafta"
                is_active={seed.is_active}
                price={seed.price}
              ></SeedCard>
            </a>
          );
          setSeedList(tempseedlist);
        });
        if (catName !== "") {
          setProductLoad(true);
          if (loader && loader.current) {
            loader.current.style.display = "none";
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  if (!loadCategory) {
    axios
      .get(globals.baseUrl + "/Category/all")
      .then((response) => {
        const categories = response.data;
        console.log(categories);
        categories.forEach((category) => {
          categorylist.push(
            <a href={`/seeds/${category.id}`}>{category.categoryName}</a>
          );
          setCategories(categorylist);
          droplist.push(
            <Dropdown.Item href={`/seeds/${category.id}`}>
              {category.categoryName}
            </Dropdown.Item>
          );
          setDrops(droplist);
        });
        setCategoryLoad(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return (
    <div>
      <Navbar />
      <div className="hero">
        <div className="categories">
          <strong id="top-category">{catName}</strong>
          <hr style={{ width: "100%" }} />
          <div className="others">{categories}</div>
        </div>

        <div className="dropdown-categories">
          <strong id="top-category">{catName}</strong>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Kategoriler
            </Dropdown.Toggle>

            <Dropdown.Menu>{drops}</Dropdown.Menu>
          </Dropdown>
        </div>

        <div class="loader" ref={loader}>
          <div class="📦"></div>
          <div class="📦"></div>
          <div class="📦"></div>
          <div class="📦"></div>
          <div class="📦"></div>
        </div>

        <div class="seeds">{seed_list}</div>
      </div>

      <Footer />
    </div>
  );
};

export default CategorizedSeeds;

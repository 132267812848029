import React from "react";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";
import twitter from "../icons/x.svg";
import youtube from "../icons/youtube.svg";
import logo from "../ornekresimler/sehirbahcelogo.jpg";
import "../styles/component-styles/footer.css";
import { ToastContainer } from "react-toastify";

const Footer = () => {
  return (
    <div className="footer">
      <a href="/">
        <div className="footer-brand">
          <img src={logo} alt="" id="logo" />
          <h4 id="sehirbahceleri">Şehirbahçeleri</h4>
        </div>
      </a>
      <hr id="hr" />
      <br />
      <div className="footer-links" id="asagimargin">
        <ul id="footer-links">
          <li>
            <a href="/yardim">Yardım</a>
          </li>
          <li>
            <a href="/contact">İletişim</a>
          </li>
          <li>
            <a href="gizlilik_ve_güvenlik">Gizlilik ve Güvenlik</a>
          </li>
        </ul>
      </div>
      <hr id="hr" />
      <div className="footer-icons" id="asagimargin">
        <ul id="footer-icons">
          <li>
            <a
              href="https://www.facebook.com/?locale=tr_TR"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/login?lang=tr"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/cosmoseed_"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@Sehirbahceleri"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="" style={{ color: "black" }} />
            </a>
          </li>
        </ul>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Footer;

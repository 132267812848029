import React, { useEffect, useState } from 'react';
import '../styles/component-styles/cargotest.css';

export default function CargoBar({ shipmentStatus, trackingLink, cancelReason }) {
    const [status, setStatus] = useState(null);

    const statuses = ['Onay Bekleniyor', 'Hazırlanıyor', 'Kargoya Verildi', 'Teslim Edildi', 'İptal Edildi'];

    useEffect(() => {
        switch (shipmentStatus) {
            case 0:
                setStatus('Onay Bekleniyor');
                break;
            case 1:
                setStatus('Hazırlanıyor');
                break;
            case 2:
                setStatus('Kargoya Verildi');
                break;
            case 3:
                setStatus('Teslim Edildi');
                break;
            case -1:
                setStatus('İptal Edildi');
                break;
            default:
                setStatus('Onay Bekleniyor');
        }
    }, [shipmentStatus]);

    useEffect(() => {
        if (status && status !== 'İptal Edildi') {
            const currentIndex = statuses.indexOf(status);
            for (let i = 0; i <= currentIndex; i++) {
                setTimeout(() => {
                    const element = document.getElementById(statuses[i]);
                    if (element) {
                        element.classList.add('completed');
                        element.style.animation = 'fillCircle 0.5s forwards';
                    }
                    if (i > 0) {
                        const barElement = document.getElementById(`bar-${i}`);
                        if (barElement) {
                            barElement.classList.add('completed');
                        }
                    }
                }, i * 500);
            }
        }
    }, [status]);

    return (
        <div>
            <div className="status-bar">
                {status !== 'İptal Edildi' ? (
                    statuses.slice(0, -1).map((s, index) => (
                        <React.Fragment key={s}>
                            <div
                                id={s}
                                className={`status-item ${s === status ? 'active' : ''}`}
                            >
                                <span>{s}</span>
                            </div>
                            {index < statuses.length - 2 && (
                                <div id={`bar-${index + 1}`} className="status-bar-item"></div>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <div className="status-item İptal Edildi">
                        <span>İptal Edildi</span>
                    </div>
                )}
            </div>
            {status === 'İptal Edildi' && (
                <div className="İptal Edildi-description">
                    <div className="description-box">
                        <p>{cancelReason || 'Siparişiniz iptal edildi.'}</p>
                    </div>
                </div>
            )}
            <div className="tracking-button-container">
                {trackingLink && (
                    <a href={trackingLink} className="tracking-button" target="_blank" rel="noopener noreferrer">Kargo Takip</a>
                )}
            </div>
        </div>
    );
}

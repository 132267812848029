import React from "react";
import "../styles/component-styles/seedcard.css";

function SeedCard(props) {
  return (
    <div
      className="seedcard"
      style={props.is_active === false ? { background: "red" } : null}
    >
      <div className="seed-img-container">
        <img
          src={props.image}
          alt="seedimg"
          className="seed-img"
          loading="lazy"
        />
      </div>
      <div className="seedinfo">
        {props.is_active === false ? <p>Yetersiz Stok</p> : null}
        <div className="info">
          <p id="name">{props.name}</p>
          <div className="grow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
            >
              <path
                d="M3.92331 17.0627C7.02366 20.1631 14.1435 24.4755 22.2139 17.0194C17.6519 16.1418 16.8493 10.3374 13.7483 7.23771C10.648 4.13673 6.32197 3.43565 3.22099 6.53601C0.120638 9.63636 0.822337 13.9617 3.92331 17.0627Z"
                fill="#95AD85"
              />
              <path
                d="M15.2465 17.843C19.0417 18.855 21.8473 17.3859 22.1061 17.1271C22.1061 17.1271 19.7614 18.2115 15.6905 16.2129C16.2801 15.4623 18.0774 14.4113 18.0774 14.4113C17.7349 14.197 15.5518 14.272 14.0611 15.2963C13.3439 14.8424 12.5846 14.2949 11.7863 13.6396L12.3065 13.1002C14.3193 11.0044 16.6071 11.4998 16.6071 11.4998C16.212 11.1202 13.1395 9.36874 10.6721 11.9334L10.2925 12.3285C10.0286 12.0814 9.76232 11.8256 9.49106 11.5543L8.3453 10.4086L9.00427 9.74901C11.0611 7.69284 14.0989 7.61171 14.0989 7.61171C13.7112 7.22339 9.84841 5.97111 7.33332 8.4862L6.8775 8.94141L1.74203 3.8047C1.35433 3.41639 0.694129 3.44859 0.307049 3.83567C-0.08065 4.22337 -0.112236 4.88295 0.275463 5.27065L5.41155 10.4067L5.37501 10.4433C2.85992 12.959 3.9041 17.0292 4.29242 17.4175C4.29242 17.4175 4.58227 14.171 6.63905 12.1148L6.87873 11.8745L9.047 14.0434C9.0823 14.0787 9.11698 14.1091 9.15229 14.1444C7.65042 17.1153 9.91344 20.5482 10.3649 20.8232C10.3649 20.8232 9.45761 17.8981 10.6622 15.4753C11.6073 16.2154 12.5326 16.7728 13.4226 17.1816C13.474 19.0352 15.0991 20.7117 15.4936 20.8548C15.4936 20.8548 14.9294 18.995 15.1629 18.0759L15.2465 17.843Z"
                fill="#527A6C"
              />
              <path
                d="M13.6356 5.57413C13.3904 5.57413 13.1581 5.42673 13.0609 5.18519C12.9339 4.8681 13.0869 4.50889 13.4034 4.38068C13.5062 4.33857 15.8906 3.32907 16.1383 0.563769C16.1693 0.223758 16.4629 -0.0252113 16.8103 0.00203908C17.1515 0.0323861 17.4024 0.333379 17.372 0.67401C17.0549 4.21099 13.9961 5.47689 13.866 5.52892C13.7905 5.55988 13.7124 5.57413 13.6356 5.57413ZM16.7323 8.05144C16.4759 8.05144 16.2362 7.89103 16.1476 7.63587C16.0355 7.31382 16.2046 6.96204 16.5254 6.84871C16.6418 6.80659 19.3279 5.78904 19.8574 1.77765C19.902 1.43888 20.2048 1.19858 20.5523 1.24503C20.8917 1.28962 21.1301 1.60052 21.0849 1.93991C20.4526 6.7397 17.0791 7.96783 16.936 8.01738C16.8685 8.04029 16.7998 8.05144 16.7323 8.05144ZM18.5909 11.7674C18.3722 11.7674 18.161 11.651 18.0477 11.446C17.883 11.1468 17.9926 10.7703 18.2917 10.6055C18.3084 10.5963 19.9955 9.65426 20.5219 8.42675C20.6569 8.11213 21.0223 7.96721 21.3345 8.10161C21.6491 8.236 21.7946 8.60016 21.6596 8.91478C20.9641 10.5374 18.9724 11.6448 18.8881 11.6912C18.7934 11.7426 18.6918 11.7674 18.5909 11.7674Z"
                fill="#527A6C"
              />
            </svg>
            <p id="grow">{props.grow}</p>
          </div>
        </div>
        {/* <p id="credit">{props.price} Cr</p> */}
      </div>
    </div>
  );
}

export default SeedCard;

import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import shopping_bag from "../icons/shopping-bag.svg";
import user_icon from "../icons/user.svg";
import logo from "../ornekresimler/sehirbahcelogo.jpg";
import { IsClientAuthorized, SendHTTPRequest } from "../requestModule";
import "../styles/component-styles/navbar.css";
import "../styles/component-styles/sidebar.css";

function Navbar() {
  const navigate = useNavigate();
  const Sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [credit, setCredit] = useState(0);

  async function LogoutBTN(event) {
    //var btn = document.getElementById("logoutbtn");

    //if(btn.hasAttribute("disabled")) return;
    //btn.setAttribute("disabled", null);

    const toastid = toast.loading("Oturum Kapatılıyor...", {
      closeOnClick: true,
    });

    var result = await SendHTTPRequest("POST", "/Auth/logout");

    if (result && result.status === 200) {
      toast.update(toastid, {
        render: "Oturum Kapatıldı",
        type: "success",
        isLoading: false,
        autoClose: true,
      });

      await Sleep(1000);

      navigate("/");
    } else {
      toast.update(toastid, {
        render: "Oturum Kapatılamadı",
        type: "error",
        isLoading: false,
        autoClose: true,
      });
      //btn.removeAttribute("disabled");
    }
    console.log(event);
  }

  useEffect(() => {
    IsClientAuthorized(false).then((auth) => {
      setLoggedIn(auth);
    });
  });

  if (isLoggedIn) {
    SendHTTPRequest("GET", "/Schools").then((response) =>
      setCredit(response.data.credit)
    );
  }

  return (
    <div
      className="navbarContainer"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="navbar">
        <a href="/">
          <div className="brand">
            <img src={logo} alt="" id="logo" />
            <h4 id="sehirbahceleri">Şehirbahçeleri</h4>
          </div>
        </a>
        <div className="links" id="yukarımargin">
          <ul id="links">
            <li>
              <a href="/seeds" id="link">
                Tohumlar
              </a>
            </li>
            <li>
              <a href="/bitkibakimi" id="link">
                Bitki Bakımı
              </a>
            </li>
            <li>
              <a href="/hakkimizda" id="link">
                Hakkımızda
              </a>
            </li>
          </ul>
        </div>
        <div className="icons" id="yukarımargin">
          <ul id="icons">
            <li id="searchpart">
              {isLoggedIn ? (
                // <h5>Toplam Cr: {credit}</h5>
                <h5>Hoşgeldiniz</h5>
              ) : (
                <a href="/login" id="login-href">
                  Giriş Yapın
                </a>
              )}
            </li>

            <div className="shopveuser">
              <a href="/basket">
                <li>
                  <img
                    class="icon"
                    id="icon-bag"
                    src={shopping_bag}
                    alt="to-shopping-bag"
                  />
                </li>
              </a>

              {isLoggedIn ? (
                <div class="dropdown">
                  <div class="btn-group dropstart">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        class="icon"
                        id="icon-user"
                        src={user_icon}
                        alt="to-user-page"
                      />
                    </button>
                    <ul class="dropdown-menu" style={{ textAlign: "center" }}>
                      <li>
                        <b>Kullanıcı İşlemleri</b>
                      </li>
                      <hr />
                      <li>
                        <a href="/order_history">Geçmiş Siparişlerim</a>
                      </li>
                      <hr />
                      <li>
                        <p onClick={LogoutBTN} style={{ cursor: "pointer" }}>
                          Çıkış Yap{" "}
                          <i class="fa-solid fa-right-from-bracket"></i>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <a href="/login">
                  <li>
                    <img
                      class="icon"
                      id="icon-user"
                      src={user_icon}
                      alt="to-user-page"
                    />
                  </li>
                </a>
              )}
            </div>
          </ul>
        </div>
      </div>
      <div class="sidebar" id="sidebar">
        <div className="sidebarlinks">
          <a href="/seeds" id="link">
            Tohumlar
          </a>
          <a href="/bitkibakimi" id="link">
            Bitki Bakımı
          </a>
          <a href="/hakkimizda" id="link">
            Hakkımızda
          </a>
        </div>
        <div className="searchbox">
          {isLoggedIn ? (
            <h5
              style={{ width: "100%", color: "white", textAlign: "center" }}
              className="bg-success"
            >
              Toplam Cr: {credit}
            </h5>
          ) : (
            <button className="btn btn-success" style={{ width: "100%" }}>
              Giriş Yap
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { SendHTTPRequest } from "../requestModule.js";
import "../styles/page-styles/order-history.css";
import OrderHistoryCard from "../components/orderHistoryCard.jsx";

function OrderHistory() {
  const [orderHistoryData, setOrderHistoryData] = useState();
  const [orderHistoryLoaded, setOrderHistoryLoaded] = useState(false);
  const loader = useRef();


  function GetStatusTitleByIndex(index)
  {
    const arr = ["Onay Bekleniyor","Hazırlanıyor", "Kargoya Verildi", "Teslim Edildi"];

    return index == -1 ? "İptal Edildi" : arr[index];
  }

  useEffect(() => {
    if (!orderHistoryLoaded) {
      if (loader && loader.current) {
        loader.current.style.display = "flex";
      }

      SendHTTPRequest("GET", "/Order").then((response) => {
        const orders = response.data;
        let orderList = [];
        orders.forEach((order) => {
          const orderData = {
            id: order.id,
            price: order.price,
            status: order.status,
            timestamp: order.timestamp,
            shipmentStatus: order.shipmentStatus
          };

          orderList.push(orderData);
        });

        setOrderHistoryData(orderList);
        console.log(orderHistoryData);
        setOrderHistoryLoaded(true);
        if (loader && loader.current) {
          loader.current.style.display = "none";
        }
      });
    }
  }, [orderHistoryLoaded]);

  return (
    <div className="orderHistoryContainer">
      <div>
        <Navbar />

        <h1>Sipariş Geçmişi</h1>
        <hr />

        <div className="history">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "150px",
            }}
            ref={loader}
          >
            <div className="loader">
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
              <div className="📦"></div>
            </div>
          </div>
          <div className="orderHistoryCards">
            {orderHistoryData &&
              orderHistoryData.map((order, index) => (
                <OrderHistoryCard
                  key={index}
                  id={order.id}
                  price={order.price}
                  status={GetStatusTitleByIndex(order.shipmentStatus)}
                  timestamp={order.timestamp}
                />
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default OrderHistory;

import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import '../styles/page-styles/help.css';
import green_mark from "../ornekresimler/green_mark.png";

function Help() {
    return (
        <div className="help_page">
            <div className='help_cont'>
                <Navbar />

                    <div id='mark'>
                        <img src={green_mark} alt="mark" id='marklogo' />
                    </div>
                    <h1 id="help">Yardım</h1>


                <div class="vl"></div>

                <p id="help_text">Sizlere en iyi hizmeti sunmak için çalışıyoruz. Eğer yardıma ihtiyacınız varsa {<a href="/contact" id='href_link'>iletişim formunu doldurabilir </a>} ya da {<a href="mailto: sehirbahceleri@gmail.com" id='href_link'>sehirbahceleri@gmail.com </a>} adresi üzerinden bizlere ulaşabilirsiniz.</p>

            </div>
            <Footer></Footer>
        </div>

    )
}
export default Help;

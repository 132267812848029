import { Analytics } from "@vercel/analytics/react"; //Vercel Analytics
import { SpeedInsights } from "@vercel/speed-insights/react"; //Vercel SpeedInsights
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import more from "../icons/more.jpg";
import Swal from "sweetalert2";
import introduction from "../ornekresimler/hero.jpg";
import Cookies from "js-cookie";
import "../styles/page-styles/home.css";
import { Helmet } from "react-helmet";

const Home = () => {
  const tohumbilgi = Cookies.get("tohumbilgi");
  if (!tohumbilgi) {
    const inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000);
    Cookies.set("tohumbilgi", true, { expires: inOneMinute });
    Swal.fire({
      title:
        "•⁠  ⁠Okullarımız; gönderilen tohumların yetiştirilmesi sonrasında elde ettikleri yeni tohumları, Nakkaştepe Kampüsünde yer alan tohum bankasına göndererek yeniden tohum talep hakkı elde edebilmektedir. \n\n•⁠  ⁠Tohum bankasına gönderilen tohum sayısı, okullarımıza gönderilen tohum sayısının 2 katı olmalıdır.",
      icon: "info",
      position: "center",
      allowOutsideClick: true,
      confirmButtonText: "Ana Sayfaya Geri Dön",
      showConfirmButton: false,
    });
  }
  return (
    <div>
      <Helmet htmlAttributes={{ lang: "tr" }} />
      <Navbar />
      <div className="home-hero">
        <div className="introduction">
          <div className="content">
            <div className="textpart1">
              <p id="title">
                Güzel Bir Gelecek İçin <strong>"Şehirbahçeleri"</strong>
              </p>
              <button id="shopnow">
                <a href="/seeds" style={{ color: "white" }}>
                  Tohum Talep Edin
                </a>
              </button>
            </div>
            <div className="textpart2">
              <a href="/seeds">
                <div className="buttongroup">
                  <p id="more">Daha Fazlası </p>
                  <img src={more} alt="arrow" id="moreimage" />
                </div>
              </a>
            </div>
          </div>
          <hr />
          <img src={introduction} alt="introduction" id="introduction-image" />
        </div>

        <div className="featured-products"></div>
        <div className="gallery"></div>
        <div className="subscription"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

/*<div className="steps">
<img src={image} alt="process" id="process-image"/>
<div className="information">
  <p>Step {count+1}</p>
  <p>{proccessData[count].information}</p>
  <div className="arrows">
    <button onClick={() => {
      if (count <= 0) {
        setCount(0)
      }
      else {
        setCount(count-1)
      }
    }}><i class="fa-solid fa-arrow-left"></i></button>
    <button onClick={() => {
      if (count >= 3) {
        setCount(3)
      }
      else {
        setCount(count+1)
        console.log(proccessData);
      }
    }}><i class="fa-solid fa-arrow-right"></i></button>
   </div>
</div>
</div>*/

/*
              <div className="textpart2-texts">
                <h4 id='h6'>Gel vatandaş gel.</h4>
                <h4 id='h6'>Seramızdan evlerinize.</h4>
                <h4 id='h6'>En tazeleri burada.</h4>
              </div>
*/

import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "../styles/page-styles/contact.css";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import axios from "axios";
import globals from "../globals";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  let consistentValue;
  const { register, getValues } = useForm();

  const recaptchaRef = useRef();

  function onChange(value) {
    console.log("captcha value: ", value);
    consistentValue = value;
  }

  const handleContact = (event) => {
    event.preventDefault();

    if (getValues("message").length < 10) {
      toast.error("Mesajınız en az 10 karakter olmalı");
      return;
    }

    if (getValues("mail").length < 1) {
      toast.error("Lütfen mail adresinizi girin");
      return;
    }
    if (getValues("name").length < 1) {
      toast.error("Lütfen adınızı girin");
      return;
    }
    if (consistentValue === undefined) {
      toast.error("Lütfen Captcha'yı doldurun");
      return;
    }

    const toastid = toast.loading("Mesaj gönderiliyor...");

    axios
      .post(
        globals.baseUrl + "/contact",
        {
          name: getValues("name"),
          email: getValues("mail"),
          message: getValues("message"),
        },
        { params: { captcha: consistentValue } }
      )

      .then((response) => {
        if (response.status === 200) {
          toast.update(toastid, {
            render: "Mesajınız Gönderildi",
            type: "success",
            isLoading: false,
            autoClose: true,
          });
          document.getElementById("myForm").reset();
        } else {
          toast.update(toastid, {
            render: "Mesaj gönderilemedi, lütfen tekrar deneyin",
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          switch (error.response.data) {
            case "IncorrectCaptcha":
              toast.update(toastid, {
                render: "Lütfen captchayı doldurun",
                type: "error",
                isLoading: false,
                autoClose: true,
              });
              break;
            case "invalidemail":
              toast.update(toastid, {
                render: "Lütfen geçerli bir mail adresi girin",
                type: "error",
                isLoading: false,
                autoClose: true,
              });
              break;

            default:
              toast.update(toastid, {
                render: "Mesaj gönderilemedi, lütfen tekrar deneyin",
                type: "error",
                isLoading: false,
                autoClose: true,
              });
              break;
          }
        } else {
          toast.update(toastid, {
            render: "Mesaj gönderilemedi, lütfen tekrar deneyin",
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
      });
  };

  return (
    <div className="deneme2">
      <div>
        <Navbar />
        <h1 class="contactHead">İletişim Formu</h1>
        <form onSubmit={handleContact} className="contactForm" id="myForm">
          <input
            required
            type="text"
            id="fname"
            name="fname"
            placeholder="Adınız ve Soyadınız"
            className="contactFormName"
            minLength={-1}
            {...register("name")}
          />
          <input
            type="mail"
            id="fmail"
            name="fmail"
            placeholder="Mail Adresiniz*"
            className="contactFormMail"
            {...register("mail")}
          />
          <textarea
            type="text"
            id="fname"
            name="fname"
            placeholder="Mesajınız*"
            className="contactFormMessage"
            minLength={10}
            {...register("message")}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={globals.captchaSiteKey}
            onChange={onChange}
            type="image"
            className="captcha-cl"
            required
          />
          <ToastContainer />
          <input type="submit" />
        </form>
      </div>
      <div className="foterrr">
        <Footer />
      </div>
    </div>
  );
}
export default Contact;

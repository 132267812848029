import { Analytics } from "@vercel/analytics/react"; //Vercel Analytics
import { SpeedInsights } from "@vercel/speed-insights/react"; //Vercel SpeedInsights
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Basket from "./pages/basket";
import Bitkibakımı from "./pages/bitkibakım";
import CategorizedSeeds from "./pages/categorized-seeds";
import Contact from "./pages/contact";
import Hakkımız from "./pages/hakkımız";
import Help from "./pages/help";
import Home from "./pages/home";
import Login from "./pages/login";
import Profile from "./pages/profile";
import Security from "./pages/security";
import SeedDetail from "./pages/seed_detail";
import Seeds from "./pages/seeds";
import PageNotFound from "./pages/error-page";
import Order from "./pages/order";
import OrderHistory from "./pages/order-history";
import OrderHistoryDetail from "./pages/order-history-detail";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Helmet htmlAttributes={{ lang: "tr" }} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/gizlilik_ve_güvenlik" element={<Security />} />
        <Route path="/yardim" element={<Help />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="/hakkimizda" element={<Hakkımız />} />
        <Route path="/seeds/seed/:id" element={<SeedDetail />} />
        <Route path="/seeds" element={<Seeds />} />
        <Route path="/seeds/:category" element={<CategorizedSeeds />} />
        <Route path="/bitkibakimi" element={<Bitkibakımı />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/order" element={<Order />} />
        <Route path="/order/:id" element={<OrderHistoryDetail />} />
        <Route path="/order_history" element={<OrderHistory />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </BrowserRouter>
  );
}

export default App;

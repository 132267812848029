import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import OrderSeedCard from "../components/order_seedcard";
import globals from "../globals";
import { SendHTTPRequest } from "../requestModule";
import "../styles/page-styles/order.css";
import { IsClientAuthorized } from "../requestModule";
import { useNavigate } from "react-router-dom";

function Order() {
  const navigate = useNavigate(); //Initialize navigation

  let consistentValue;
  const { register, getValues } = useForm();
  const loader = useRef();
  const [basketData, setBasketData] = useState([]);
  const [basketLoaded, setBasketLoaded] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [username, setUsername] = useState("");
  const [credit, setCredit] = useState(0);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const recaptchaRef = useRef();

  function setCaptcha(value) {
    console.log("captcha value: ", value);
    consistentValue = value;
  }

  useEffect(() => {
    SendHTTPRequest("GET", "/Users").then((response) => {
      setUsername(response.data.username);
    });
    if (!basketLoaded) {
      if (loader && loader.current) {
        loader.current.style.display = "flex";
      }

      SendHTTPRequest("GET", "/basket").then((response) => {
        const seeds = response.data.seeds;

        const basketList = seeds.map((seed, index) => ({
          id: seed.id,
          image: globals.baseUrl + `/products/${seed.id}/image`,
          name: seed.name,
          price: seed.price,
          quantity: seed.quantity,
        }));
        setBasketData(basketList);
        setTotalPrice(
          basketList.reduce(
            (total, seed) => total + seed.price * seed.quantity,
            0
          )
        );
        setBasketLoaded(true);
        if (loader && loader.current) {
          loader.current.style.display = "none";
        }
      });
    }
  }, [basketLoaded]);

  const handleOrder = async (event) => {
    event.preventDefault();

    if (getValues("adress").length < 20) {
      showErrorToast("Adres uzunluğu en az 20 karakter uzunluğunda olmalıdır!");
      return;
    }

    if (getValues("mail").length < 1) {
      showErrorToast("Lütfen mail adresinizi girin!");
      return;
    }

    if (getValues("phoneNumber")[0] === "0") {
      showErrorToast("Telefon numaranızın ilk hanesi 0 olamaz!");
      return;
    }
    if (getValues("phoneNumber").length != 10) {
      showErrorToast("Telefon numaranız 10 haneli olmalıdır!");
      return;
    }
    if (consistentValue === undefined) {
      showErrorToast("Lütfen Captcha'yı doldurun");
      return;
    }

    try {

      const toastid = showLoadingToast("Sipariş İşleniyor...");

      const response = await SendHTTPRequest("POST", "/Order", {
        address: getValues("adress"),
        phoneNumber: getValues("phoneNumber"),
        email: getValues("mail"),
      }, { captcha: consistentValue });

      if (response === null || response.status !== 200) {
        toast.error("Hata Oluştu. Lütfen tekrar deneyin.");
        return;
      }

      if(response.status === 400) {
        switch (response.data) {
          case "IncorrectCaptcha":
            updateErrorToast(toastid, "Lütfen captchayı doldurun");
            break;
          case "invalidemail":
            updateErrorToast(toastid, "Lütfen geçerli bir mail adresi girin");
            break;
          case "invalidphone":
            updateErrorToast(toastid, "Lütfen geçerli bir telefon numarası girin");
            break;
          case "invalidaddress":
            updateErrorToast(toastid, "Lütfen geçerli bir adres girin");
            break;
          case "nobasket":
            updateErrorToast(toastid, "Sepetinizde ürün bulunmamaktadır");
            break;
          case "insufficientfunds":
            updateErrorToast(toastid, "Yeterli krediniz bulunmamaktadır");
            break;
          default:
            updateErrorToast(toastid, "Sipariş alınamadı, lütfen tekrar deneyin");
            break;
        }
      }
      else if(response.status !== 200) {
        updateErrorToast(toastid, "Hata oluştu, lütfen tekrar deneyin");
        }
      else {
        updateSuccessToast(toastid, "Siparişiniz Alındı. Yönlendiriliyorsunuz...");
        setTimeout(() => navigate(`/order/${response.data}`), 2000);
      }


      

    }
    catch (error) {
        toast.error("Sunucuya bağlanırken hata oluştu. Lütfen tekrar deneyin.");
    }
    finally {
        recaptchaRef.current.reset();
    }
  };

  // Yardımcı fonksiyonlar
  const showLoadingToast = (message) => {
    return toast.loading(message);
  };

  const showErrorToast = (message) => {
    toast.error(message);
  };

  const updateSuccessToast = (toastid, message) => {
    toast.update(toastid, {
      render: message,
      type: "success",
      isLoading: false,
      autoClose: true,
    });
  };

  const updateErrorToast = (toastid, errorMessage) => {
    toast.update(toastid, {
      render: errorMessage,
      type: "error",
      isLoading: false,
      autoClose: true,
    });
  };

  useEffect(() => {
    IsClientAuthorized(false).then((auth) => {
      setLoggedIn(auth);
    });
  });

  if (isLoggedIn) {
    SendHTTPRequest("GET", "/Schools").then((response) =>
      setCredit(response.data.credit)
    );
  }

  function CancelProcess() {
    SendHTTPRequest("DELETE", "/basket/all").then((response) => {
      if (response === null || response.status !== 200) {
        const toastid = showErrorToast("Sepet sıfırlanırken bir hata oluştu");
        return;
      }
      const toastid = showLoadingToast("Sepet Sıfırlanıyor...");

      updateSuccessToast(toastid, "Sepet sıfırlandı");

      setBasketLoaded(false);
    });
    showErrorToast("Siparişiniz iptal edildi");
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return (
    <div className="orderContainer">
      <div>
        <Navbar />
        <div className="order">
          <form className="orderForm" onSubmit={handleOrder} id="orderForm">
            <div className="order-inputs">

              <input
                required
                type="text"
                id="order-phone"
                name="order-phone"
                placeholder="Telefon Numaranız*"
                className="orderFormPhone"
                minLength={10}
                maxLength={10}
                {...register("phoneNumber")}
              />
              <input
                required
                type="email"
                id="order-mail"
                name="order-mail"
                placeholder="Mail Adresiniz*"
                className="orderFormMail"
                {...register("mail")}
              />
              <textarea
                type="text"
                id="order-adress"
                name="order-adress"
                placeholder="Adresiniz*"
                className="orderFormMessage"
                minLength={20}
                rows={3}
                {...register("adress")}
              />
            </div>
            <div className="orderInfo">
              <div className="orderCarts">
                <div className="loader" ref={loader}>
                  <div className="📦"></div>
                  <div className="📦"></div>
                  <div className="📦"></div>
                  <div className="📦"></div>
                  <div className="📦"></div>
                </div>
                <div className="order-seeds-container">
                  <div className="order-seeds">
                    {basketData.map((seed) => (
                      <OrderSeedCard
                        id={seed.id}
                        image={seed.image}
                        name={seed.name}
                        price={seed.price}
                        quantity={seed.quantity}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="orderFinish">
                <div className="order-other-finish">
                  <div className="ordersum">
                    <h2>TOPLAM: </h2>
                    <h2>{totalPrice} CR</h2>
                  </div>
                  <div className="orderkalan">
                    <h2>Kalan Cr: </h2>
                    {credit && credit != 0 ? (
                      <h2>
                        {credit - totalPrice < 0
                          ? "Yetersiz Kredi!"
                          : credit - totalPrice + "CR"}
                      </h2>
                    ) : (
                      <h2>Yükleniyor...</h2>
                    )}
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={globals.captchaSiteKey}
                    onChange={setCaptcha}
                    type="image"
                    className="captcha-cl"
                    id="recaptcha-second"
                  />
                  <div className="order-submit-buttons">
                    {credit - totalPrice < 0 ? (
                      <div style={{ cursor: "not-allowed" }}>
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled
                        >
                          Siparişi Tamamla
                        </button>
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-success">
                        Siparişi Tamamla
                      </button>
                    )}
                    <button type="button" onClick={CancelProcess} className="btn btn-danger" >
                      İptal Et
                    </button>
                  </div>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={globals.captchaSiteKey}
                  onChange={setCaptcha}
                  type="image"
                  className="captcha-cl"
                  id="recaptcha-first"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Order;

import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "../styles/page-styles/order-history-detail.css";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { SendHTTPRequest } from "../requestModule";
import { toast } from "react-toastify";
import OrderHistoryCard from "../components/order-history-seedcard";
import ShipmentBar from "../components/cargoTrackingBar";

const OrderHistoryDetail = () => {
  const orders = useParams();
  const id = orders.id;
  const [orderLoad, setOrderLoad] = useState(false);

  const [username, setUsername] = useState("");

  const [actualOrder, setActualOrder] = useState({});

  useEffect(() => {
    SendHTTPRequest("GET", "/Users").then((response) => {
      setUsername(response.data.username);
    });
  }, [actualOrder]);

  let orderDetails = null;

  useEffect(() => {
    if (!orderLoad) {
      (async () => {
        try {
          const response = await SendHTTPRequest("GET", `/Order/find/${id}`);
          if (response === null || response.status !== 200) {
            toast.error("Hata Oluştu");
            return;
          }
          const order = response.data;
          orderDetails = JSON.parse(order.orderDetails);

          setActualOrder({
            email: order.email,
            phoneNumber: order.phoneNumber,
            address: order.address,
            details: JSON.parse(order.orderDetails),
            shipmentStatus: order.shipmentStatus,
            shipmentTrackingLink: order.shipmentTrackingLink,
            orderCancelReason: order.orderCancelReason,
          });
          setOrderLoad(true);
        } catch (error) {
          console.error(
            "Sipariş detayları getirilirken bir hata oluştu:",
            error
          );
          toast.error("Sipariş detayları getirilirken bir hata oluştu");
        }
      })();
    }
  }, [id, orderLoad]);

  return (
    <div>
      <Navbar />
      <div className="OrderHistoryDetailContainer">

        <br />
        <h3>KARGO DURUMU</h3>

        <br />

        <ShipmentBar
          shipmentStatus={actualOrder.shipmentStatus}
          cancelReason={actualOrder.orderCancelReason}
          trackingLink={actualOrder.shipmentTrackingLink}
        />
        <br />

        <div className="OrderInfoContainer">
          <div className="order-personal">

            <h3>Telefon Numarası</h3>
            <input
              type="text"
              minLength={-1}
              value={actualOrder.phoneNumber}
              disabled
            />

            <h3>E-Posta</h3>
            <input
              type="text"
              minLength={-1}
              value={actualOrder.email}
              disabled
            />

            <h3>Adres</h3>
            <textarea
              type="text"
              minLength={20}
              value={actualOrder.address}
              rows="3"
              disabled
            />

          </div>

          <div>
            {actualOrder.details &&
              actualOrder.details.map((seed) => (
                <OrderHistoryCard
                  name={seed.Name}
                  quantity={seed.Quantity}
                  key={seed.Id}
                  price={seed.Price}
                />
              ))}
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default OrderHistoryDetail;

import React from "react";
import "../styles/page-styles/errorpage.css";

function errorpage() {
  return (
    <div className="err">
      <h1 id="error">404</h1>
    </div>
  );
}
export default errorpage;

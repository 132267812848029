import axios from "axios";
import globals from "./globals";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from "js-cookie"


const refreshTokenString = "refreshtoken";//burdan degistir iste idc 


const Sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const RawRequest = async (method, url, data, parameters = null) => {
  try {
    const response = await axios({
      method: method,
      url: globals.baseUrl + url,
      data: data,
      params: parameters,
      withCredentials: true
    });
    return response;

  } catch (error) {
    return error.response;
  }
};

async function SendHTTPRequest(method, url, data, parameters = null, alertIfNotAuth = true) {
  console.trace();
  console.log("SENDING REQ");
  const response = await RawRequest(method, url, data, parameters);
  console.log("SENT REQ");
  console.log(response);

  if (!response) return null;

  if (response.status === 401 && alertIfNotAuth) {
    console.log("SSTAUS 401");
    if (response.data === "expired") {
      console.log("EXPIRED SWALL");
      await Swal.fire({
        title: "Uzun süre işlem yapılmadığı için oturumunuz kapatılmıştır.",
        icon: "warning",
        position: "top",
        allowOutsideClick: false,
        confirmButtonText: "Ana Sayfaya Geri Dön",
        showConfirmButton: true
      });

      document.location = "/";

      return null;
    }
    else if (response.data === "nosession") {
      Swal.fire({
        title: "Oturum bilgisi alınamadı. Ana sayfaya yönlendiriliyorsunuz.",
        icon: "warning",
        position: "top",
        allowOutsideClick: false,
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: false
      });

      await Sleep(2500);

      document.location = "/";

      return null;
    }
  }

  if (response.status === 403) {
    Swal.fire({
      title: "Bu sayfayı görüntülemek için yetkiniz yok.",
      text: '"Clever perhaps, but definitely not wise."',
      icon: "warning",
      position: "top",
      allowOutsideClick: false,
      showConfirmButton: false,
      showDenyButton: false,
      showCloseButton: false
    });

    await Sleep(2500);

    document.location = "/";

    return null;
  }

  return response;

};


async function IsClientAuthorized(alertIfNotAuth) {
  var resp = await SendHTTPRequest("GET", "/Auth", null, null, alertIfNotAuth);
  return resp && resp.status !== 401;
}

export { SendHTTPRequest, IsClientAuthorized };

import { SpeedInsights } from "@vercel/speed-insights/react"; //Vercel SpeedInsights
import { Analytics } from "@vercel/analytics/react"; //Vercel Analytics
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import SeedCard from "../components/seedcard";
import globals from "../globals";
import ornektohum from "../ornekresimler/ornektohum.jpg";
import "../styles/page-styles/seeds.css";
import { SendHTTPRequest } from "../requestModule";
import { toast } from "react-toastify";

const Seeds = () => {
  const loader = useRef();

  const [productLoaded, setProductLoaded] = useState(false);
  const [categoryLoaded, setCategoryLoaded] = useState(false);

  const tempseedlist = [];
  const [seed_list, setSeedList] = useState([]);

  const categorylist = [];
  const [categories, setCategories] = useState([]);

  const droplist = [];
  const [drops, setDrops] = useState([]);

  (async () => {
    if (productLoaded) return;
    setProductLoaded(true);
    if (loader && loader.current) loader.current.style.display = "flex";

    var response = await SendHTTPRequest("GET", `/Products?Page=1&PageSize=30`);
    if (response == null) {
      toast.error("Hata Oluştu");
      return false;
    }

    const seeds = response.data;

    seeds.forEach((seed) => {
      if (seed.image == null || seed.image === "string") {
        seed.image = ornektohum;
      }
      tempseedlist.push(
        <a href={`/seeds/seed/${seed.id}`}>
          <SeedCard
            image={globals.baseUrl + `/Products/${seed.id}/image`}
            name={seed.name}
            grow="5-6 hafta"
            is_active={seed.is_active}
            price={seed.price}
          ></SeedCard>
        </a>
      );
    });
    setSeedList(tempseedlist);
    if (loader && loader.current) loader.current.style.display = "none";
  })();

  if (!categoryLoaded) {
    axios
      .get(globals.baseUrl + "/Category/all")
      .then((response) => {
        const categories = response.data;
        console.log(categories);
        categories.forEach((category) => {
          categorylist.push(
            <a href={`/seeds/${category.id}`}>{category.categoryName}</a>
          );
          setCategories(categorylist);
          droplist.push(
            <Dropdown.Item href={`seeds/${category.id}`}>
              {category.categoryName}
            </Dropdown.Item>
          );
          setDrops(droplist);
        });
        setCategoryLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div id="seeds-container">
      <div>
        <Navbar />
        <div className="hero">
          <div className="categories">
            <strong id="top-category">Yeni Ürünler</strong>
            <hr style={{ width: "100%" }} />
            <div className="others">{categories}</div>
          </div>

          <div className="dropdown-categories">
            <strong id="top-category">Yeni Ürünler</strong>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Kategoriler
              </Dropdown.Toggle>

              <Dropdown.Menu>{drops}</Dropdown.Menu>
            </Dropdown>
          </div>

          <hr style={{ width: "100%" }} id="category-hr" />

          <div class="loader" ref={loader}>
            <div class="📦"></div>
            <div class="📦"></div>
            <div class="📦"></div>
            <div class="📦"></div>
            <div class="📦"></div>
          </div>

          <div class="seeds">{seed_list}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Seeds;

import React, { useRef, useState } from "react";
import globals from "../globals";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import user_icon from '../icons/user.svg';
import { SendHTTPRequest } from '../requestModule';
import '../styles/page-styles/login.css';
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
    let consistentValue;
    
  const recaptchaRef = useRef();

  function onChange(value) {
    console.log("captcha value: ", value);
    consistentValue = value;
  }


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const Sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleLogin = async (event) => {
        event.preventDefault();

        const btn = document.getElementById("loginbtn");

        if (btn.hasAttribute("disabled")) {
            return;
        }


        btn.setAttribute("disabled", null);

        const toastid = toast.loading("Giriş Yapılıyor...");

        const response = await SendHTTPRequest("POST", `/Auth/login?username=${username}&password=${password}&captcha=${consistentValue}`);

        if (response == null) toast.update(toastid, { render: "Hata oluştu, lütfen tekrar deneyin", type: "error", isLoading: false, autoClose: true });
        else {

            switch (response.status) {
                case 200:
                    toast.update(toastid, { render: "Giriş Yapıldı", type: "success", isLoading: false, autoClose: true });

                    await Sleep(2000);

                    document.location = "/";
                    return;

                case 401:
                    toast.update(toastid, { render: "Hatalı Kullanıcı Adı veya Şifre", type: "error", isLoading: false, autoClose: true });
                    break;
                case 400:
                    if(response.data == "IncorrectCaptcha")
                    toast.update(toastid, { render: "Lütfen Captchayı Doldurun", type: "error", isLoading: false, autoClose: true });
                    else
                    toast.update(toastid, { render: "Hata oluştu, lütfen tekrar deneyin", type: "error", isLoading: false, autoClose: true });
                    break;

                default:
                    toast.update(toastid, { render: "Hata oluştu, lütfen tekrar deneyin", type: "error", isLoading: false, autoClose: true });
                    break;
            }
        }


        recaptchaRef.current.reset();
        btn.removeAttribute("disabled");
    }

    return (
        <div className='login-cont'>
            <div>
                <Navbar />
                <form onSubmit={handleLogin} className='login_form'>
                    <img class='icon' id='login-icon' src={user_icon} alt="..." />
                    <div className='input-cont'>
                        <label id='labl'>Kullanıcı Adı: </label>
                        <input type="text" placeholder='Kullanıcı Adı' onChange={handleChangeUsername} value={username} id='inpt' />
                    </div>

                    <div className='input-cont'>
                        <label id='labl'>Şifre: </label>
                        <input type="password" placeholder='Şifre' onChange={handleChangePassword} value={password} id='inpt' />
                    </div>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={globals.captchaSiteKey}
                        onChange={onChange}
                        type="image"
                        className="captcha-cl"
                        required
                    />

                    <input id='loginbtn' type="submit" className='btn btn-success login-sub' value="Giriş Yap" />
                </form>
            </div>

            <Footer />
        </div>
    )
}
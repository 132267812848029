import React, { useEffect } from 'react';
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../styles/page-styles/profile.css";
import user_icon from '../icons/user.svg';
import trash from '../icons/Icon.svg';
import { useNavigate } from 'react-router-dom';
import { SendHTTPRequest, IsClientAuthorized } from '../requestModule';
import { ToastContainer, toast } from 'react-toastify';

export default function Profile() {
  const navigate = useNavigate();
  const Sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  useEffect(() => {
    IsClientAuthorized(false).then((auth) => {
      if(!auth) navigate("/login");
    });
  });

  async function LogoutBTN(event)
  {
    var btn = document.getElementById("logoutbtn");

    if(btn.hasAttribute("disabled")) return;
    btn.setAttribute("disabled", null);
    
    const toastid = toast.loading("Oturum Kapatılıyor...");

    var result = await SendHTTPRequest("POST", "/Auth/logout");

    if(result && result.status == 200)
    {
      toast.update(toastid, { render: "Oturum Kapatıldı", type: "success", isLoading: false, autoClose: true });

      await Sleep(1000);
      
      navigate("/");
    }
    else
    {
      toast.update(toastid, { render: "Oturum Kapatılamadı", type: "error", isLoading: false, autoClose: true });
      btn.removeAttribute("disabled");
    }

    console.log(event);
  }

  return (
    <div className="profile_cont">  
        <div> 
          <Navbar/>
          <div className='account-set'>
            <h2>Hesap Ayarları</h2>
            <hr />

            <div className='account-content'>
              <div className='account-list-div'>
                <ul className='account-list-ul'>
                  <li><a href='/profile' id='account-list-component'>Profil</a></li>
                  <li><a href="#" id='account-list-component'>Kredi Geçmişi</a></li>
                  <li><a href="#" id='account-list-component'>İletişim Bilgileri</a></li>
                  <li><a href="#" id='account-list-component'>Şifre ve Güvenlik</a></li>
                </ul>
              </div>
              <div className='account-right'>
                <div className='profile-image'>
                  <img src={user_icon} alt="" />
                  <button>Güncelle</button>


                  <div className="account-inputs">
                    <div className='input-box'>
                      <label>Kullanıcı Adı: </label>
                      <input type="text" />
                    </div>
                    <div className='input-box'>
                      <label>Adres: </label>
                      <textarea cols="70" rows="10"></textarea>
                    </div>
                    <div className='input-box'>
                      <label>Telefon Numarası: </label>
                      <input type="text" placeholder='+90 XXX XXX XX XX'/>
                    </div>
                  </div>

                  <div className="account-button-group">
                    <div className='left-buttons'>
                      <button className='btn btn-primary'>Kaydet</button>
                      <button className='btn btn-outline-dark'>İptal</button>
                    </div>
                    <div className="trash">
                    <img src={trash} alt="" />
                    <button className='btn btn-danger' id='logoutbtn' onClick={LogoutBTN}>Oturumu Kapat</button>
                  </div>

                  </div>
                </div>
              </div>
            </div>
            
          </div> 
        </div>
        
        <Footer/>
    </div>
  )
}

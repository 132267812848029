import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import '../styles/page-styles/security.css';
import security_icon from "../ornekresimler/security-svgrepo-com.svg";

function Security() {
  
  return (
    <div className="security">
      <div className='scrty'>
        <Navbar />
        
        <div id='security_icon'>
          <img src={security_icon} alt="security_icon" id='securityiconvector' />
        </div>
        <h1 id="gizlilik">Gizlilik ve Güvenlik</h1>
        <div class="vl"></div>
        
        <p id="lorem">Lorem ipsum dolor sit amet consectetur adipisicing elit. In, provident tempore dolor ducimus, obcaecati molestiae eveniet cum laboriosam fuga magni porro, distinctio sed voluptatibus vitae ullam ad amet enim nesciunt?</p>
        
      </div>
      <Footer></Footer>
  </div>
    

  )
}
export default Security;

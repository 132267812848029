import React from "react";

const OrderHistoryCard = (props) => {
  let dateString = props.timestamp;
  let date = new Date(dateString);
  let dateYear =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  return (
    <div className="orderHistoryCard">
      <p>
        <strong>Sipariş Tarihi:</strong> {dateYear}
      </p>
      <p>
        <strong>Harcanan Kredi:</strong> {props.price} Cr
      </p>
      <p>
        <strong>Sipariş Durumu:</strong> {props.status}
      </p>
      <a href={`order/${props.id}`}>
        <button className="btn btn-danger">Detayları İncele</button>
      </a>
    </div>
  );
};

export default OrderHistoryCard;

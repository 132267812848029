import React, { useState } from "react";
import { toast } from "react-toastify";
import { SendHTTPRequest } from "../requestModule";
import "../styles/component-styles/basket_seedcard.css";

function BasketSeedCard(props) {
  const seedprice = props.price;
  const seedid = props.id;
  let [amount, setI] = useState({
    number: props.quantity,
    price: props.price * props.quantity,
  });

  const addseed = () => {
    setI({ number: amount.number + 1, price: amount.price + seedprice });
    props.onAmountChange(seedprice);

    SendHTTPRequest(
      "POST",
      `/basket?productId=${props.id}&quantity=${amount.number + 1}`
    );
  };

  const deleteseed = () => {
    if (amount.number > 1) {
      setI({ number: amount.number - 1, price: amount.price - seedprice });
      props.onAmountChange(-seedprice);

      SendHTTPRequest(
        "POST",
        `/basket?productId=${seedid}&quantity=${amount.number - 1}`
      );
    }
  };

  const handleTrash = () => {
    SendHTTPRequest("DELETE", `/Basket?productId=${seedid}`)
      .then(() => {
        window.location.reload();
        toast.success("Ürün başarıyla silindi.");
      })
      .catch((error) => {
        console.error("Silme işlemi sırasında bir hata oluştu:", error);
        toast.error("Ürün silinirken bir hata oluştu.");
      });
  };
  
  return (
    <div className="seedcardbasket">
      <div className="basketseed-img-container">
        <img
          src={props.image}
          alt="basketseed-img"
          className="basketseed-img"
        />
      </div>
      <div className="basketseedinfo">
        <div className="basketinfo">
          <p id="basketname">{props.name}</p>
        </div>
        <div className="count-and-numero-and-buttons">
          <div className="numero">
            <p id="basketseednumber">{amount.number}x</p>
          </div>
          <div className="basket_seed_card_buttons">
            <button
              className="btn btn-outline-danger"
              id="delete"
              onClick={deleteseed}
            >
              -
            </button>
            <button
              className="btn btn-outline-success"
              id="add"
              onClick={addseed}
            >
              +
            </button>
          </div>
        </div>
        <div className="basketcreditcont">
          <p id="basketcredit">{amount.price} Cr</p>
        </div>
        <i
          class="fa-solid fa-trash"
          style={{
            marginRight: "20px",
            fontSize: "25px",
            cursor: "pointer",
            color: "red",
          }}
          onClick={handleTrash}
        ></i>
      </div>
    </div>
  );
}

export default BasketSeedCard;

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import BasketSeedCard from "../components/basket_seedcard";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import ornektohum from '../ornekresimler/ornektohum.jpg';
import { SendHTTPRequest } from '../requestModule';
import "../styles/page-styles/basket.css";
import globals from '../globals';
import { Router, useNavigate } from 'react-router-dom';

export default function Basket() {
    const loader = useRef();
    const [basketData, setBasketData] = useState([]);
    const [basketLoaded, setBasketLoaded] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();
    
    const handleBasketDelete = () => {

        var toastid = toast.loading("Sepet temizleniyor...");

        SendHTTPRequest("DELETE", "/basket/all").then((response) => {
            if(response === null || response.status !== 200)
            {
                toast.update(toastid,  { render: "Hata Oluştu", type: "error", isLoading: false, autoClose: true });
                return;
            }

            toast.update(toastid,  { render: "Sepet Sıfırlandı", type: "success", isLoading: false, autoClose: true });
            setBasketLoaded(false)

        });
    }
    const routeToOrder = () => {
        
        navigate("/order");

    }

    const routeToSeeds = () => {
        
        navigate("/seeds");

    }
    
    useEffect(() => {
        if (!basketLoaded) {
            if (loader && loader.current) {
                loader.current.style.display = "flex";
            }
            
            SendHTTPRequest("GET", "/basket").then((response) => {
                const seeds = response.data.seeds;

                const basketList = seeds.map((seed, index) => ({
                    id: seed.id,
                    image: globals.baseUrl + `/products/${seed.id}/image`,
                    name: seed.name,
                    price: seed.price,
                    quantity: seed.quantity
                }));
                setBasketData(basketList);
                setTotalPrice(basketList.reduce((total, seed) => total + (seed.price * seed.quantity), 0));
                setBasketLoaded(true);
                if (loader && loader.current) {
                    loader.current.style.display = "none";
                }
            });
        }
    }, [basketLoaded]);

    const handleAmountChange = (change) => {
        setTotalPrice(totalPrice + change);
    };

    return (
        <div className="basket-container">
            <div>
                <Navbar />
                <div>
                    <h1 id="Basket">Sepetiniz: </h1>
                    <hr />
                    <div className='seedandinfo'>
                        <div className="loader" ref={loader}>
                            <div className="📦"></div>
                            <div className="📦"></div>
                            <div className="📦"></div>
                            <div className="📦"></div>
                            <div className="📦"></div>
                        </div>
                        <div className='basket-seeds'>
                            {basketData.map((seed) => (
                                <BasketSeedCard
                                    id={seed.id}
                                    image={seed.image}
                                    name={seed.name}
                                    price={seed.price}
                                    quantity={seed.quantity}
                                    onAmountChange={handleAmountChange}
                                />
                            ))}
                        </div>
                        <div className="cart-info">
                            <h4>Toplam Sepet Tutarı: {totalPrice}</h4>
                            <div className="buttons">
                            <button className='btn btn-outline-success' style={{ width: "100%" }} onClick={routeToSeeds}>Alışverişe Devam Et</button>
                            <button className='btn btn-outline-danger' style={{ width: "100%" }} onClick={handleBasketDelete}>Sepeti Boşalt</button>
                            </div>
                            <br />
                            <button className='btn btn-success' style={{ width: "100%" }} onClick={routeToOrder}>Siparişi Tamamla</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

// import {SendHTTPRequest } from "../requestModule.js";
// import React, { useRef, useState } from 'react';
// import BasketSeedCard from "../components/basket_seedcard";
// import Footer from "../components/footer";
// import Navbar from "../components/navbar";
// import globals from '../globals';
// import ornektohum from '../ornekresimler/ornektohum.jpg';
// import "../styles/page-styles/basket.css";

// export default function Basket() {
//   const loader = useRef();

//   const basketlist = [];
//   const [basketSeeds, setBasketSeeds] = useState([]);
//   const [basketLoaded, setBasketLoaded] = useState(false);

//   if (!basketLoaded) {
//     if (loader && loader.current) {

//       loader.current.style.display = "flex"
//     }

//     SendHTTPRequest("GET", "/Products?Page=1&PageSize=30").then((response) => {
//       const seeds = response.data;
//       seeds.forEach((seed) => {
//         if (seed.image == null || seed.image === "string") {
//           seed.image = ornektohum;
//         }
//         basketlist.push(
//           <BasketSeedCard image={seed.image} name={seed.name} price={seed.price} />
//         );
//         setBasketSeeds(basketlist);
//       })
//       setBasketLoaded(true);
//       if (loader && loader.current) {

//         loader.current.style.display = "none"
//       }
//     })
    
//   }

//   return (
//     <div className="basket-container">
//       <div>
//         <Navbar />
//         <div>
//           <h1 id="Basket">Sepetiniz: </h1>
//           <hr />
//           <div className='seedandinfo'>
//             <div class="loader" ref={loader}>
//               <div class="📦"></div>
//               <div class="📦"></div>
//               <div class="📦"></div>
//               <div class="📦"></div>
//               <div class="📦"></div>
//             </div>
//             <div className='basket-seeds'>
//               {basketSeeds}
//             </div>
//             <div className="cart-info">
//               <h4>Toplam Sepet Tutarı: </h4>
//               <button className='btn btn-outline-success' style={{ width: "100%" }}>Sepeti Ele Geçir</button>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer></Footer>
//     </div>
//   )
// }

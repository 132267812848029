import React from 'react';
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../styles/page-styles/bitkibakımı.css";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function Bitkibakım() {
  const navigate = useNavigate();



  Swal.fire({
    title: "Çok Yakında...",
    icon: "info",
    position: "center",
    allowOutsideClick: false,
    confirmButtonText: "Ana Sayfaya Geri Dön",
    showConfirmButton: true
  }).then(() => {
    navigate("/");
  });


  return (
    <div className="deneme2">
      <div>
        <Navbar />

        <h1>BİTKİ BAKIMI</h1>
        <hr />
      </div>

      <Footer />
    </div>
  )
}

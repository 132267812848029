import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import globals from "../globals";
import { SendHTTPRequest } from "../requestModule";
import "../styles/page-styles/seed_detail.css";

const SeedDetail = () => {
  const seeds = useParams();
  const id = seeds.id;
  let imgClicked = false;
  let IMGstyleDef = null;

  const [catName, setCatName] = useState("");
  const [seedLoad, setSeedLoad] = useState(false);

  const [ActualSeed, setSeed] = useState([]);

  (async () => {
    if (seedLoad) return;

    var response = await SendHTTPRequest("GET", `/Products/${id}`);

    if (response === null || response.status !== 200) {
      toast.error("Hata Oluştu");
      return;
    }
    const seed = response.data;

    setSeed({
      image: globals.baseUrl + `/Products/${id}/image`,
      active: seed.is_active,
      name: seed.name,
      price: seed.price,
      description: seed.description,
      stock: seed.stock,
      schools: seed.contributorSchoolNames,
      stock: seed.stock,
    });
    setCatName(seed.categoryName);

    setSeedLoad(true);
  })();

  function AddToBasket() {
    var toastid = toast.loading("Sepete Ekleniyor...");

    SendHTTPRequest("POST", `/basket?productId=${id}&quantity=1`).then(
      (response) => {
        if (response === null || response.status !== 200) {
          toast.update(toastid, {
            render: "Hata Oluştu",
            type: "error",
            isLoading: false,
            autoClose: true,
          });
          return;
        }

        toast.update(toastid, {
          render: "Sepete Eklendi",
          type: "success",
          isLoading: false,
          autoClose: true,
        });
      }
    );
  }

  const showImg = () => {
    const newImage = document.getElementById("ornekimage");
    if (!imgClicked) {
      IMGstyleDef = newImage.style;
      newImage.style.position = "fixed";
      newImage.style.top = "10vh";
      newImage.style.left = "15vw";
      newImage.style.maxHeight = "80vh";
      newImage.style.maxWidth = "70vw";
      if (newImage.width >= newImage.height) {
        newImage.style.width = "80vh";
      }
      if (newImage.height > newImage.width) {
        newImage.style.height = "50vw";
      }
      newImage.style.zIndex = 5;
      imgClicked = true;
    } else {
      newImage.style = IMGstyleDef;
      imgClicked = false;
    }
  };

  return (
    <div id="seed-detail">
      <div>
        <Navbar></Navbar>
        <div className="container">
          <img src={ActualSeed.image} alt="" id="ornekimage" />
          {/* <img src={ActualSeed.image} alt="" id="ornekimage" onClick={showImg} /> On click*/}
          <div className="details">
            <div className="ayir">
              <div>
                <div>
                  <h4>{ActualSeed.name}</h4>
                  <p>{catName}</p>
                </div>
                {/* <h4>{ActualSeed.price} Cr</h4> */}
              </div>
              <img src={ActualSeed.image} alt="" id="ornekimage2" />
            </div>
            <div className="buttons">
              {ActualSeed.active === false ? (
                <button
                  className="btn btn-outline-primary"
                  id="sepetepostala"
                  onClick={AddToBasket}
                  disabled
                >
                  Yetersiz Stok
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  id="sepetepostala"
                  onClick={AddToBasket}
                >
                  Sepete Ekle
                </button>
              )}
            </div>
            <p>{ActualSeed.description}</p>
            <p>Kalan Stok: {ActualSeed.stock}</p>
            <p id="contributors">
              Bu Tohuma Katkıda Bulunan Okullar:
              <br /> {ActualSeed.schools}
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SeedDetail;

import React from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../styles/page-styles/hakkımız.css";
import sehirlogo from "../ornekresimler/sehirbahcelogo.jpg";

function Hakkımız() {
  return (
    <div className="deneme">
      <div className="hk">
        <Navbar />

        <div id="sehirlogo">
          <img src={sehirlogo} alt="sehirlogo" id="sehirbahcelogo" />
        </div>
        <h1 id="hakkımızda">Hakkımızda</h1>
        <div class="vl"></div>

        <p id="lorem">
          <p>Gıda Kriziyle Mücadelede Bahçeşehir Koleji'nden Ata Tohumu Projeleri:</p>
          <p>NASA verilerine göre Türkiye, %48'lik yağış düşüşüyle şiddetli bir kuraklık yaşıyor. 2050'de 10 milyara ulaşması beklenen dünya nüfusu ve iki katına çıkacak gıda ihtiyacı, olası bir gıda krizine işaret ediyor. Bahçeşehir Koleji, bu sorunlara çözüm olarak sürdürülebilir tarım ve ata tohumunun önemini vurgulamak amacıyla "Ata Tohumu" ve "Tohum Deposu" projelerini başlattı.</p>
          <p>Ata Tohumu Projesi ile Akıllı Seralarda Fide Yetiştiriciliği:</p>
          <p>2021-2022 eğitim-öğretim yılında başlayan proje kapsamında, fiziksel imkanları uygun 15 kampüste çalışmalar yürütülüyor. İstanbul, İzmir ve Ordu'daki dört kampüste kurulan akıllı seralarda öğrenciler tarafından ata tohumlarından fideler yetiştiriliyor. Bu fideler, farkındalık yaratmak amacıyla halka ücretsiz olarak dağıtılıyor. Düzenlenen tadım etkinlikleri ile ata tohumlardan yetiştirilen ürünlerin diğer ürünlerden tat, koku, lezzet olarak farklılığı vurgulanmaya çalışılıyor.</p>
          <p>Ata Tohumunun Önemi:</p>
          <p>Anadolu'da uzun yıllardır yetişen, bölgeye uyum sağlamış ve kendi tohumundan tekrar üretilebilen yerli tohumlara "ata tohumu" deniyor. Bu tohumlar; hastalıklara dirençli, iklim ve toprak koşullarına uyumlu, kimyasal ilaç kullanılmadan üretiliyor. Genetiğiyle oynanmadığı için besin değeri yüksek, sağlıklı ve lezzetli ürünler veriyor. Aynı zamanda sürdürülebilir olup, bulundukları ortama uyum sağladıkları için daha az tarım ilacına ihtiyaç duyuyorlar.</p>
          <p>Yapay Zekâ ve Nesnelerin İnterneti ile Akıllı Seralar:</p>
          <p>Bahçeşehir Koleji kampüslerindeki akıllı seralar, yapay zekâ ve Nesnelerin İnterneti (IoT) teknolojileriyle yönetiliyor. Bitkilerin ihtiyaçları sensörler aracılığıyla belirlenip otomatik olarak karşılanıyor. Yapay zekâ uygulamalarıyla hastalık ve zararlılar tespit ediliyor. Tüm süreç, öğrencilerin geliştirdiği altyapı ve kodlarla işliyor. Elde edilen bilgilerle, hobi bahçeciliği ve evlerde ata tohumu yetiştiriciliği için kitler geliştirilmesi planlanıyor.</p>
          <p>Tohum Deposu ile Ata Tohumlarının Korunması ve Paylaşımı:</p>
          <p>2022-2023 eğitim-öğretim yılında proje bir adım ileri taşınarak Bahçeşehir Koleji Nakkaştepe Kampüsünde "Tohum Deposu" kuruldu. Akıllı seralarda çoğaltılan ata tohumları bu depoda toplanacak ve diğer kampüslere transfer edilecek. İlk etapta 322 farklı çeşit sebze ve meyvenin ata tohumu depolanacak. 152 kampüs, kendi bölgelerindeki ata tohumlarını araştırarak çoğaltmaya ve halka dağıtmaya devam edecek. Böylece ata tohumları koruma altına alınacak, filizlenme oranları korunacak ve okullar arası tohum paylaşımı kolaylaşacak.</p>
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
}
export default Hakkımız;

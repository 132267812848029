import React, { useState } from 'react';
import { SendHTTPRequest } from '../requestModule';
import '../styles/component-styles/order_seedcard.css';

function OrderHistoryCard(props) {
    const seedprice = props.price;
    const seedid = props.id;
    let [amount, setI] = useState({ number: props.quantity, price: props.price * props.quantity });

    return (
        <div className='seedcardorder'>
            <div className="orderseedinfo">
                <div className="orderinfo">
                    <p id='ordername' className="orderseedinfop">{props.name}</p>
                </div>
                <div className="count-and-numero-and-buttons">
                    <div className='numero'>
                        <p id='orderseednumber' className="orderseedinfop">{amount.number}x</p>
                    </div>    
                </div>
                <div className='ordercreditcont'>
                    <p id='ordercredit' className="orderseedinfop">{amount.price} Cr</p>
                </div>
            </div>

        </div>
    );
}

export default OrderHistoryCard;